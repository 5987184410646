import { render, staticRenderFns } from "./ContactDialog.vue?vue&type=template&id=0b504fd0&scoped=true&"
import script from "./ContactDialog.vue?vue&type=script&lang=js&"
export * from "./ContactDialog.vue?vue&type=script&lang=js&"
import style0 from "./ContactDialog.vue?vue&type=style&index=0&id=0b504fd0&lang=less&scoped=true&"
import style1 from "./ContactDialog.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b504fd0",
  null
  
)

export default component.exports