<template>
  <div v-if="Object.keys(data).length" class="solutions-introduce">
    <public-banner :banner-info="bannerInfo" :is-custom="true">
      <div class="introduce-box">
        <div class="introduce-top">
          <div class="text-box">
            <h4 class="name">{{ data.solution_name }}</h4>
            <p class="desc" v-html="data.short_intro"></p>
            <div
              class="mobile-img-box"
              :style="`background: url(${data.cover_image}) center center / cover no-repeat`"
            ></div>
            <public-label
              v-for="(item, index) in data.solution_type"
              :key="index"
              :title="item.name"
            />
          </div>
          <div
            class="img-box"
            :style="`background: url(${data.cover_image}) center center / contain no-repeat`"
          ></div>
        </div>
        <div class="introduce-bottom">
          <!-- 移动端显示按钮 -->
          <div v-if="data.partner_info.id !== 0" class="mobile-btn-box">
            <router-link
              class="btn-item"
              :to="`/partners/introduction?company_name=${
                data.partner_info.company_name.split(' by ')[1]
              }`"
            >
              <el-button>{{ data.partner_info.company_profile }}</el-button>
            </router-link>
            <el-button @click="handleContactDialog">{{
              data.partner_info.contact
            }}</el-button>
          </div>
          <ul class="partners-icon">
            <li class="partners-item">
              <div class="img-box" @click="shareFacebook">
                <span class="iconfont icon-a-facebook_facebook511x"></span>
              </div>
            </li>
            <li class="partners-item">
              <div class="img-box" @click="shareLinkedin">
                <span class="iconfont icon-a-linkedin1x1"></span>
              </div>
            </li>
            <li class="partners-item">
              <div class="img-box" @click="shareTwitter">
                <span class="iconfont icon-a-twitter1x"></span>
              </div>
            </li>
          </ul>
          <p class="partners-title">{{ data.partner_info.company_name }}</p>
          <div v-if="data.partner_info.id !== 0" class="btn-box">
            <router-link
              class="btn-item"
              :to="`/partners/introduction?company_name=${
                data.partner_info.company_name.split(' by ')[1]
              }`"
            >
              <el-button>{{ data.partner_info.company_profile }}</el-button>
            </router-link>
            <el-button @click="handleContactDialog">{{
              data.partner_info.contact
            }}</el-button>
          </div>
        </div>
      </div>
    </public-banner>
    <!-- Solotion Description -->
    <div
      v-if="data.solution_image || data.solution_description"
      class="description-box"
    >
      <public-title :title="LP.lang_solution_description" />
      <div class="desc-container container">
        <div v-if="data.solution_image" class="img-box">
          <div
            class="img-bg"
            :style="`background: url(${data.solution_image}) center center / cover no-repeat`"
          ></div>
        </div>
        <div class="text-box" :class="{ 'text-center': !data.solution_image }">
          <p class="text" v-html="data.solution_description"></p>
        </div>
      </div>
    </div>
    <!-- Interoperability -->
    <div
      v-if="data.dahua_product_icon || data.partner_product_icon"
      class="interoperability-box"
    >
      <public-title :title="LP.lang_interoperability" />
      <ul class="product-list container">
        <!-- Dahua -->
        <li
          v-if="data.dahua_product_icon.length > 0"
          class="product-item"
          :class="{ 'select-color': data.dahua_product_description !== '' }"
        >
          <h5 class="name">{{ LP.lang_supported_dahua_products }}</h5>
          <ul
            class="child-list"
            :class="{ 'child-height': data.dahua_product_icon.length <= 3 }"
          >
            <li
              v-for="(item, index) in data.dahua_product_icon"
              :key="index"
              class="child-item"
            >
              <router-link to="" class="link">
                <div class="img-box">
                  <img :src="item.image" alt="" />
                </div>
                <div class="text-box">{{ item.name }}</div>
              </router-link>
            </li>
          </ul>
        </li>
        <!-- Partner -->
        <li
          v-if="data.partner_product_icon.length > 0"
          class="product-item"
          :class="{ 'select-color': data.partner_product_description !== '' }"
        >
          <h5 class="name">{{ LP.lang_supported_partner_products }}</h5>
          <ul
            class="child-list"
            :class="{ 'child-height': data.dahua_product_icon.length <= 3 }"
          >
            <li
              v-for="(item, index) in data.partner_product_icon"
              :key="index"
              class="child-item"
              @click="handleOpenImage(item)"
            >
              <router-link to="" class="link">
                <div class="img-box">
                  <img :src="item.icon_image" alt="" />
                </div>
                <div class="text-box">{{ item.product_name }}</div>
              </router-link>
            </li>
          </ul>
        </li>
        <!-- Dahua -->
        <div
          class="product-select"
          :class="[
            { 'select-desc': isProductDesc[0] },
            { 'show-box': data.dahua_product_description === '' },
          ]"
        >
          <h5 class="name" @click="handleSelect(0)">
            {{ LP.lang_license_information }}
            <span class="iconfont icon-a-xiala1x"></span>
          </h5>
          <p
            v-show="isProductDesc[0]"
            class="desc"
            v-html="data.dahua_product_description"
          ></p>
        </div>
        <!-- Partner -->
        <div
          class="product-select"
          :class="[
            { 'select-desc': isProductDesc[1] },
            { 'show-box': data.partner_product_description === '' },
          ]"
        >
          <h5 class="name" @click="handleSelect(1)">
            {{ LP.lang_license_information }}
            <span class="iconfont icon-a-xiala1x"></span>
          </h5>
          <p
            v-show="isProductDesc[1]"
            class="desc"
            v-html="data.partner_product_description"
          ></p>
        </div>
      </ul>
    </div>
    <!-- Key Features/How It Works -->
    <div v-if="tabs1.length" class="explain-box container">
      <public-tabs-round
        :data="tabs1"
        class="explain-tab"
        @tabsClick="handleTabsWorks"
      />
      <ul v-if="featuresDefault === 0" class="features-list">
        <li
          v-for="(item, index) in data.key_features"
          :key="index"
          class="features-item"
        >
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-a-icon-a-gou1x-new" />
          </svg>
          <p class="desc">{{ item.name }}</p>
        </li>
      </ul>

      <div v-show="featuresDefault === 1" class="works-list">
        <div
          v-for="(item, index) in data.how_works"
          :key="index"
          class="works-item"
          @click="handleOpenVideo(item)"
        >
          <img
            :src="item.image"
            :preview="!item.title ? 0 : null"
            :preview-text="item.title || null"
            class="works-img"
            alt=""
          />
          <i
            v-if="item.title !== ''"
            class="iconfont icon-a-playCircle1x icon"
          ></i>
          <span v-if="item.title !== ''" class="iconfont icon-search"></span>
        </div>
      </div>
      <!-- </a> -->
      <!-- </li> -->
      <!-- </ul> -->
    </div>
    <!-- Info Center -->
    <div v-if="data.info_center_release.length > 0" class="info-box container">
      <public-title :title="LP.lang_info_center" />
      <public-tabs-square
        :data="data.info_center_release"
        class="info-tab"
        :prop="{ id: 'menu_id', label: 'menu_name' }"
        @tabsClick="handleTabsInfo"
      />
      <ul
        v-if="
          data.info_center_release.length > 0 &&
            data.info_center_release[infoDefault].info_center_list.length > 0
        "
        class="info-list"
      >
        <li
          v-for="(item, index) in data.info_center_release[infoDefault]
            .info_center_list"
          :key="index"
          class="info-item"
        >
          <div class="text-box">
            <img
              v-if="item.file_type === 1 || item.file_type === 2"
              src="@/assets/img/solution/package.png"
              alt=""
            />
            <img
              v-else-if="item.file_type === 3"
              src="@/assets/img/solution/pdf.png"
              alt=""
            />
            <img v-else src="@/assets/img/solution/unknown.png" alt="" />
            <p class="name ellipsis">{{ item.file_name }}</p>
          </div>
          <el-button
            class="info-btn"
            type="info"
            @click="handleDownload(item, data.info_center_release[infoDefault])"
          >{{ LP.lang_Download }}</el-button>
        </li>
      </ul>
    </div>
    <!-- Availability -->
    <div v-if="data.label_release.length > 0" class="availability-box">
      <public-title :title="LP.lang_availability" />
      <ul class="availability-list container">
        <li
          v-for="(item, index) in data.label_release"
          :key="index"
          class="availability-item"
        >
          <h4 class="list-title">{{ item.menu_name }}</h4>
          <ul class="child-list">
            <li class="child-item">
              <public-label
                v-for="(item, index) in item.label_list"
                :key="index"
                :title="item.name"
              />
            </li>
          </ul>
        </li>
        <li v-if="data.custom_label.length > 0" class="availability-item">
          <h4 class="list-title">{{ LP.lang_available_language }}</h4>
          <ul class="child-list">
            <li class="child-item">
              <public-label
                v-for="(item, index) in data.custom_label"
                :key="index"
                :title="item"
              />
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <!-- Disclaimer -->
    <div class="disclaimer-box">
      <div class="container">
        <h5 class="name">{{ data.disclaimer }}</h5>
        <p class="desc" v-html="data.disclaimer_description"></p>
      </div>
    </div>
    <!-- contact 弹窗 -->
    <contact-dialog
      :data="data"
      :contact-dialog="contactDialog"
      @close="handleClose"
    />
    <!-- Interoperability弹窗 -->
    <el-dialog
      title=""
      :visible.sync="imgDialogVisible"
      width="30%"
      :before-close="handleCloseimg"
      :show-close="false"
      custom-class="interoperability-dialog"
    >
      <span class="iconfont icon-close-o" @click="handleCloseimg"></span>
      <div class="interoperability-img-box">
        <img class="img-box" :src="bigImg" alt="" />
      </div>
      <span slot="footer" class="dialog-footer">
      </span>
    </el-dialog>
  </div>
</template>

<script>
import PublicBanner from '@/components/public/PublicBanner.vue'
import PublicLabel from '@/components/public/PublicLabel.vue'
import PublicTitle from '@/components/public/PublicTitle.vue'
import PublicTabsRound from '@/components/public/PublicTabsRound.vue'
import PublicTabsSquare from '@/components/public/PublicTabsSquare.vue'
import { solutionDetailApi, infoCenterHitApi } from '@/api/solutions'
import ContactDialog from '@/components/solutions/ContactDialog.vue'
import { mapState } from 'vuex'
export default {
  components: {
    PublicBanner,
    PublicLabel,
    PublicTitle,
    PublicTabsRound,
    PublicTabsSquare,
    ContactDialog
  },
  data() {
    return {
      url: '', // 存放当前页面url，用于分享
      title: '', // 存放当前页面主题标语，用于分享
      data: {}, // 详情数据
      featuresDefault: -1, // 当前tab/Key Features
      infoDefault: 0, // 当前tab/Info Center
      isProductDesc: [false, false], //  Interoperability 是否展开详情
      contactDialog: false, // 是否展示contact弹窗
      submitDialog: true,
      imgDialogVisible: false,
      bigImg: '',
      tabs1: [
        {
          name: this.LP.lang_key_features,
          id: 0
        },
        {
          name: this.LP.lang_how_it_works,
          id: 1
        }
      ]
    }
  },
  computed: {
    ...mapState({
      bannerInfo: (state) => state.common.bannerInfo
    })
  },
  created() {
    this.getBanner()
    this.getDetail()
  },
  mounted() {
    this.url = window.location.href
    this.$previewRefresh()
  },
  methods: {
    // 分享至Facebook LinkedIn Twitter
    shareFacebook() {
      window.open(
        'http://www.facebook.com/sharer.php?u=' +
          encodeURIComponent(this.url) +
          '&t=' +
          encodeURIComponent(this.title),
        '_blank',
        'toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350'
      )
    },
    shareLinkedin() {
      window.open(
        ' http://www.linkedin.com/shareArticle?url=' +
          encodeURIComponent(this.url) +
          '&text=' +
          encodeURIComponent(this.title),
        '_blank',
        'toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350'
      )
    },
    shareTwitter() {
      window.open(
        'https://twitter.com/intent/tweet?url=' +
          encodeURIComponent(this.url) +
          '&text=' +
          encodeURIComponent(this.title),
        '_blank',
        'toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350'
      )
    },
    // 获取轮播图
    async getBanner() {
      this.$store.dispatch('common/getBannerImg', { num: 132 })
    },
    // 获取解决方案详情接口
    getDetail() {
      solutionDetailApi({
        solution_id: this.$route.params.id,
        menu: 2
      }).then((res) => {
        this.data = res.data
        console.log(this.data)
        this.title = res.data.solution_name
        if (!res.data.key_features.length) {
          this.tabs1.shift()
        }
        if (!res.data.how_works.length) {
          this.tabs1.pop()
        }
        this.featuresDefault = this.tabs1[0].id
      })
    },
    // Key Features/How It Works切换
    handleTabsWorks(item, index) {
      this.featuresDefault = item.id
    },
    // Info Center切换
    handleTabsInfo(item, index) {
      this.infoDefault = index
    },
    // Interoperability展开下拉
    handleSelect(index) {
      // 当数据已经改变，但是页面无法监听变化时，数组或对象使用this.$set方法
      this.$set(this.isProductDesc, index, !this.isProductDesc[index])
    },
    // 打开视频
    handleOpenVideo(item) {
      item.title !== '' ? window.open(item.title) : ''
    },
    // Info Center下载
    handleDownload(item, info) {
      infoCenterHitApi({
        only_code: item.only_code
      }).catch((err) => err)
      window.open(item.download)
    },
    // 打开Contact弹窗
    handleContactDialog() {
      this.contactDialog = true
    },
    // 关闭Contact弹窗
    handleClose() {
      this.contactDialog = false
    },
    // 查看大图
    handleOpenImage(item) {
      console.log('item::: ', item)
      this.bigImg = item.icon_image
      this.imgDialogVisible = true
    },
    handleCloseimg() {
      this.imgDialogVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
@import "~@/styles/common/common";
.solutions-introduce {
  .round-bg {
    width: 72px;
    height: 72px;
    background-color: #ffffff;
    border-radius: 50%;
  }
  .introduce-box {
    width: 92%;
    max-width: 1280px;
    margin: 0 auto;
    min-height: 420px;
    background: #ffffff;
    box-shadow: 0px 6px 18px 0px rgba(214, 214, 214, 0.5);
    margin-top: -200px;
    position: relative;
    .introduce-top {
      display: flex;
      padding: 50px 38px;
      .text-box {
        width: 50%;
        .name {
          font-size: 32px;
          font-weight: bold;
          color: #303133;
          line-height: 43px;
        }
        .desc {
          font-size: 16px;
          color: #606266;
          line-height: 21px;
          margin: 25px 0 74px 0;
        }
        .mobile-img-box {
          display: none;
        }
        /deep/ .public-label {
          margin: 0 10px 12px 0;
        }
      }
      .img-box {
        width: calc(50% - 74px);
        height: 320px;
        margin-left: 74px;
        background: #f5f7fa;
      }
    }
    .introduce-bottom {
      padding: 15px 38px;
      border-top: 1px solid #e4e7ed;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .mobile-btn-box {
        display: none;
      }
      .partners-icon {
        display: flex;
        .partners-item {
          & + .partners-item {
            margin-left: 16px;
          }
          .img-box {
            cursor: pointer;
            & > .iconfont {
              font-size: 24px;
            }
          }
        }
      }
      .partners-title {
        font-size: 16px;
        color: #909399;
        line-height: 21px;
      }
      .btn-box {
        /deep/ .el-button {
          border: 1px solid rgba(28, 104, 177, 0.36);
          & > span {
            font-size: 16px;
            font-weight: bold;
            line-height: 21px;
            color: #1060ad;
          }
          &:nth-child(2) {
            border: 1px solid rgba(49, 155, 216, 0.36);
            & > span {
              color: @theme;
            }
          }
        }
        .btn-item {
          margin-right: 16px;
        }
      }
    }
  }
  .description-box {
    padding-bottom: 45px;
    .desc-container {
      display: flex;
      justify-content: center;
      align-items: center;
      .img-box {
        width: calc(50% - 70px);
        max-width: 540px;
        margin-right: 70px;
        .img-bg {
          width: 100%;
          max-width: 540px;
          height: 360px;
        }
      }
      .text-box {
        width: 50%;
        max-width: 594px;
        .text {
          font-size: 16px;
          color: #606266;
          line-height: 21px;
        }
      }
      .text-center {
        width: 100%;
        max-width: 100%;
        text-align: center;
      }
    }
  }
  .interoperability-box {
    background: #f4f4f5;
    padding: 32px 0;
    .product-list {
      display: flex;
      flex-wrap: wrap;
      .product-item {
        position: relative;
        width: calc(50% - 70px);
        min-height: 400px;
        margin-right: 140px;
        padding: 24px 62px;
        background: #ffffff;
        &::before,
        &::after {
          position: absolute;
          content: "";
          display: block;
          width: 72px;
          height: 72px;
          border-radius: 50%;
          background: #ffffff;
        }
        &::before {
          right: -48px;
          top: calc(50% - 36px);
        }
        &::after {
          bottom: -24px;
          left: calc(50% - 36px);
          background: #f4f4f5;
        }
        &:nth-child(2) {
          margin-right: 0;
          &::before {
            top: -48px;
            left: calc(50% - 36px);
          }
          &::after {
            left: -24px;
            top: calc(50% - 36px);
            background: #f4f4f5;
          }
        }
        .name {
          font-size: 24px;
          font-weight: bold;
          color: #303133;
          line-height: 32px;
          text-align: center;
        }
        .child-list {
          display: flex;
          flex-wrap: wrap;
          margin-top: 42px;
          &.child-height {
            justify-content: center;
          }
          .child-item {
            width: calc(33.333333% - 12px);
            margin: 0 18px 25px 0;
            text-align: center;
            &:nth-child(3n) {
              margin-right: 0;
            }
            .link {
              display: block;
              .img-box {
                max-width: 50px;
                margin: 0 auto;
                & > img {
                  width: 100%;
                }
              }
              .text-box {
                font-size: 16px;
                color: #333333;
                line-height: 21px;
                margin-top: 20px;
              }
            }
          }
          &:nth-child(2) {
            margin-top: 57px;
          }
        }
      }
      .select-color {
        &::after {
          background: #e5e5e5;
        }
      }
      .product-select {
        width: calc(50% - 70px);
        background: #e5e5e5;
        border-radius: 2px;
        margin-right: 140px;
        padding: 16px;
        text-align: center;
        align-self: flex-start;
        &:nth-child(4) {
          margin-right: 0;
        }
        .name {
          font-size: 16px;
          font-weight: 600;
          line-height: 21px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          .iconfont {
            color: #979797;
            font-size: 24px;
            font-weight: 400;
            margin-left: 24px;
          }
        }
        .desc {
          font-size: 16px;
          color: #606266;
          line-height: 21px;
          margin-top: 10px;
        }
      }
      .select-desc {
        .name {
          .iconfont {
            transform: rotate(180deg);
          }
        }
      }
      .show-box {
        opacity: 0;
        padding: 0;
        height: 0;
      }
    }
  }
  .explain-box {
    margin-top: 72px;
    .explain-tab {
      text-align: center;
    }
    .features-list {
      .features-item {
        display: flex;
        align-items: center;
        & + .features-item {
          margin-top: 12px;
        }
        .icon {
          width: 30px;
          height: 30px;
          flex-shrink: 0;
        }
        .desc {
          font-size: 16px;
          color: #333333;
          line-height: 21px;
          margin-left: 36px;
        }
      }
    }
    .works-list {
      display: flex;
      flex-wrap: wrap;
      .works-item {
        width: calc(33.333333% - 10.8px);
        margin-right: 16px;
        position: relative;
        cursor: pointer;
        &:nth-child(3n) {
          margin-right: 0;
        }
        .works-img{
          width: 100%;
        }
        .iconfont {
          font-size: 66px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .icon {
          color: #f5f5f5;
          opacity: 0.8;
        }
      }
    }
  }
  .info-box {
    .info-tab {
      justify-content: center;
    }
    .info-list {
      .info-item {
        padding: 14px 18px;
        background: #f5f7fa;
        display: flex;
        align-items: center;
        justify-content: space-between;
        & + .info-item {
          margin-top: 10px;
        }
        .text-box {
          display: flex;
          align-items: center;
          .name {
            font-size: 18px;
            font-weight: 600;
            color: #303133;
            line-height: 24px;
            margin-left: 18px;
          }
        }
        .info-btn {
          margin-left: 16px;
        }
        /deep/ .el-button {
          min-width: 135px;
          padding: 4px 20px;
          & > span {
            font-size: 18px;
            font-weight: bold;
            line-height: 24px;
          }
        }
      }
    }
  }
  .availability-box {
    .availability-list {
      padding: 8px 0 56px 0;
      .availability-item {
        display: flex;
        flex-wrap: wrap;
        & + .availability-item {
          margin-top: 12px;
        }
        .list-title {
          font-size: 18px;
          font-weight: bold;
          line-height: 24px;
          flex-shrink: 0;
        }
        .child-list {
          display: flex;
          flex-wrap: wrap;
          margin-left: 24px;
          /deep/ .public-label {
            margin: 0 16px 16px 0;
          }
        }
      }
    }
  }
  .disclaimer-box {
    background: #f4f4f5;
    padding: 24px 0 40px 0;
    .name {
      font-size: 24px;
      font-weight: 400;
      color: #303133;
      line-height: 32px;
    }
    .desc {
      font-size: 16px;
      color: #606266;
      line-height: 21px;
      margin-top: 16px;
    }
  }
  @media screen and (max-width: 1024px) {
    .introduce-box {
      .introduce-top {
        padding: 32px 24px;
        .text-box {
          .name {
            font-size: 28px;
            line-height: 38px;
          }
          .desc {
            margin: 16px 0 48px 0;
          }
        }
      }
      .introduce-bottom {
        padding: 12px 24px;
      }
    }
    .description-box {
      .desc-container {
        .img-box {
          width: calc(50% - 30px);
          margin-right: 30px;
        }
      }
    }
    .interoperability-box {
      .product-list {
        .product-item {
          width: calc(50% - 30px);
          min-height: 0;
          margin-right: 60px;
          padding: 24px 16px;
          &::before,
          &::after {
            width: 50px;
            height: 50px;
          }
          &::before {
            right: -32px;
            top: calc(50% - 25px);
          }
          &::after {
            left: calc(50% - 25px);
          }
          &:nth-child(2) {
            &::before {
              top: -32px;
              left: calc(50% - 25px);
            }
            &::after {
              top: calc(50% - 25px);
            }
          }
          .name {
            font-size: 18px;
            line-height: 26px;
          }
          .child-list {
            .child-item {
              width: calc(33.333333% - 8px);
              margin: 0 12px 20px 0;
              .link {
                .text-box {
                  font-size: 14px;
                  margin-top: 10px;
                }
              }
            }
          }
        }
        .product-select {
          width: calc(50% - 30px);
          margin-right: 60px;
        }
      }
    }
    .explain-box {
      .works-list {
        .works-item {
          .link {
            .iconfont {
              font-size: 46px;
            }
          }
        }
      }
    }
    .info-box {
      .info-list {
        .info-item {
          padding: 10px 12px;
          .text-box {
            .iconfont {
              font-size: 18px;
            }
            .name {
              font-size: 16px;
              margin-left: 12px;
            }
          }
          /deep/ .el-button {
            padding: 8px 10px;
          }
        }
      }
    }
    .disclaimer-box {
      .name {
        font-size: 20px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .introduce-box {
      margin-top: 0;
      box-shadow: none;
      width: 100%;
      .introduce-top {
        padding: 36px 5.35% 14px;
        .text-box {
          width: 100%;
          .name {
            text-align: center;
            font-size: 20px;
            line-height: 27px;
          }
          .desc {
            font-size: 14px;
            line-height: 19px;
            margin: 10px 0 18px;
          }
          .mobile-img-box {
            display: inline-block;
            width: 100%;
            height: 119px;
            margin-bottom: 19px;
          }
          /deep/.public-label {
            margin: 0 10px 5px 0;
          }
        }
        .img-box {
          display: none;
        }
      }
      .introduce-bottom {
        padding: 20px 5.5% 31px;
        box-shadow: 0px 6px 6px 0px rgba(214, 214, 214, 0.5);
        flex-direction: column;
        .btn-box {
          display: none;
        }
        .mobile-btn-box {
          display: block;
          /deep/ .el-button {
            border: 1px solid rgba(28, 104, 177, 0.36);
            & > span {
              font-size: 16px;
              font-weight: bold;
              line-height: 21px;
              color: #1060ad;
            }
            &:nth-child(2) {
              border: 1px solid rgba(49, 155, 216, 0.36);
              & > span {
                color: @theme;
              }
            }
          }
          .btn-item {
            margin-right: 16px;
          }
        }
        .partners-icon {
          margin: 20px auto 15px;
        }
        .partners-title {
          font-size: 14px;
          color: #909399;
          line-height: 19px;
        }
      }
    }
    .description-box {
      padding-bottom: 24px;
      .desc-container {
        flex-direction: column;
        .img-box {
          width: 100%;
          margin-right: 0;
          .img-bg {
            height: 223px;
            max-width: 100%;
          }
        }
        .text-box {
          width: 100%;
          max-width: 100%;
          padding-top: 10px;
          .text {
            font-size: 14px;
            line-height: 19px;
          }
        }
      }
    }
    .interoperability-box {
      padding: 10px 0 28px;
      /deep/.public-title {
        .title {
          margin-bottom: 16px;
        }
      }
      .product-list {
        flex-direction: column;
        width: 100%;
        overflow-x: hidden;
        .product-select {
          width: 100%;
        }
        .product-item {
          width: 100%;
          margin-right: 0;
          padding: 16px 20px 27px;
          .name {
            font-size: 14px;
            line-height: 19px;
          }
          &:nth-child(2) {
            margin-top: 43px;
            padding-bottom: 68px;
          }
          .child-list {
            // margin: 27px 0 16px;
            margin-top: 24px;
            .child-item {
              width: calc(33.333333% - 18px);
              margin: 0 24px 27px 0;
              &:last-child {
                margin-right: 0;
              }
              &:nth-child(3n) {
                margin-right: 0;
              }
              .link {
                .text-box {
                  font-size: 12px;
                  line-height: 16px;
                  margin-top: 7px;
                }
                .img-box {
                  & > img {
                    width: 56%;
                  }
                }
              }
            }
          }
        }
        .select-color {
          &:after {
            background-color: rgb(244, 244, 245);
          }
        }
      }
    }
    .explain-box {
      margin-top: 24px;
      width: 100%;
      padding: 0 15px;
      .explain-tab {
        /deep/.public-tabs-round {
          .tabs-content {
            .tabs-item {
              .tabs-name {
                font-size: 24px;
                line-height: 32px;
              }
            }
          }
        }
      }
      .features-list {
        .features-item {
          position: relative;
          & + .features-item {
            margin-top: 10px;
          }
          .icon {
            position: absolute;
            top: 0;
            width: 20px;
            height: 20px;
            margin: 0 7px 0 5px;
          }
          .desc {
            margin-left: 32px;
            font-size: 14px;
            line-height: 19px;
          }
        }
      }
      .works-list{
        display: flex;
        flex-wrap: wrap;
        .works-item{
          width: calc(50% - 8px);
          margin-bottom: 16px;
          &:nth-child(3n) {
          margin-right: 16px;

          }
          &:nth-child(2n) {
            margin-right: 0;
          }
        }
      }
    }
    .info-box {
      /deep/.public-title {
        .title {
          margin: 24px 0 16px;
        }
      }
      /deep/.public-tabs-square {
        .tabs-item {
          .tabs-name {
            font-size: 14px;
            font-weight: 600;
            line-height: 19px;
            padding: 5px 7px;
          }
        }
      }
      .info-list {
        .info-item {
          padding: 4px 7px;
          .text-box {
            width: calc(100% - 88px);
            .name {
              font-size: 12px;
              line-height: 16px;
              margin-left: 10px;
            }
          }
          /deep/.el-button {
            min-width: 60px;
            padding: 3px 5px;
            margin: 10px 0 10px 28px;
            & > span {
              font-size: 10px;
              line-height: 14px;
            }
          }
        }
      }
    }
    .availability-box {
      /deep/.public-title {
        .title {
          margin: 24px 0 10px;
        }
      }
      .availability-list {
        padding: 0 0 40px 0;
        .availability-item {
          flex-direction: column;
          & + .availability-item {
            margin-top: 2px;
          }
          .list-title {
            font-size: 14px;
            line-height: 19px;
            margin-bottom: 10px;
          }
          .child-list {
            margin-left: 0;
            /deep/.public-label {
              margin: 0 10px 8px 0;
              padding: 1px 8px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 480px){
    .explain-box {
      .works-list{
        .works-item{
          width: 100%;
          margin-bottom: 16px;

          margin-right: 0;
          &:nth-child(3n){
          margin-right: 0;

          }

        }
      }
    }
  }
}
</style>

<style lang="less" scoped>
.solutions-introduce {
  .public-title {
    .title {
      font-size: 36px;
      line-height: 48px;
      margin-top: 72px;
    }
  }
  .interoperability-box {
    .public-title {
      .title {
        margin-top: 0;
        margin-bottom: 78px;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .solutions-introduce {
    .public-title {
      .title {
        font-size: 28px;
        line-height: 48px;
        margin-top: 40px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .public-banner {
    .banner-bg {
      display: none;
    }
  }
  .solutions-introduce {
    .public-title {
      .title {
        font-size: 24px;
        line-height: 32px;
        margin-top: 31px;
      }
    }
  }
  .public-tabs-round {
    margin-bottom: 16px;
    .tabs-content {
      // width: 100%;
      justify-content: space-between;
      padding: 8px 10px;
    }
  }
}
</style>

<style lang="less">
.interoperability-dialog {
  width: 92% !important;
  max-width: 800px !important;
  min-height: 600px;
  .iconfont {
    position: absolute;
    top: 0;
    right: -18px;
    z-index: 999;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    top: -46px;
    right: -52px;
    font-size: 48px;
    color: rgb(255 255 255);
  }
  .interoperability-img-box {
    text-align: center;
    .img-box {
      max-width: 100%;
    }
  }
  .el-dialog__wrapper {
    background: rgb(0, 0, 0, 0.5);
  }
  @media screen and (max-width: 768px) {
    width: 92% !important;
    min-height: 360px;
    .iconfont {
      top: -40px;
      right: 0;
      font-size: 34px;
    }
  }
}
</style>
