<template>
  <div class="solutions-wrap">
    <public-banner :banner-info="bannerInfo" :is-search="true" @search="handleSearch" />
    <!-- Filter by -->
    <div class="filter-by-box container" :class="{mobileFilter:isMobileFilter}">
      <div class="filter-by-title">
        <a class="link" @click="handleFold">
          <span class="title">{{ LP.lang_filter_by }}</span>
          <span class="iconfont icon-a-xiala1x" :class="{default: isDefault}"></span>
        </a>
      </div>
      <!-- 移动端标题 -->
      <div class="mobile-filter-by-title">
        <a class="link" @click="handleFold">
          <span class="title">{{ LP.lang_filter_by }}</span>
          <span class="iconfont icon-a-xiala1x" :class="{default: isDefault}"></span>
        </a>
      </div>
      <!-- 完整数据 -->
      <ul v-if="isDefault" class="filter-by-list">
        <li v-for="(item, index) in labelList" :key="index" class="filter-by-item">
          <h4 class="list-title">{{ item.menu_name }}:</h4>
          <ul class="child-list">
            <li class="child-item">
              <label-check v-model="item.value" :options="item.label_list" :prop="{id: 'id', label: 'name'}" />
            </li>
          </ul>
        </li>
      </ul>
      <!-- 选中数据 -->
      <div v-else class="default-list">
        <label-check v-model="defaultList" :options="defaultList" :prop="{id: 'id', label: 'name'}" />
      </div>
      <div v-if="!isMobile ||isDefault" class="filter-by-btn">
        <el-button type="primary" @click="handleScreen">{{ LP.lang_dipp_search }}</el-button>
        <el-button type="info" @click="handleReset">{{ LP.lang_reset }}</el-button>
      </div>
    </div>
    <div class="solutions-list">
      <ul class="list container">
        <li v-for="(item, index) in data.solutionInfo" :key="index" class="list-item">
          <router-link :to="`/solutions_services/Introduction/${item.solution_id}`" class="link">
            <div class="img-box">
              <div class="img-bg" :style="`background: url(${item.cover_image}) center center / contain no-repeat`"></div>
            </div>
            <div class="text-box">
              <h4 class="title ellipsis">{{ item.solution_name }}</h4>
              <public-label v-for="(subItem, subIndex) in item.label_arr" :key="subIndex + 'label'">{{ subItem.name }}</public-label>
              <public-label v-for="(subItem, subIndex) in item.custom_label_arr" :key="subIndex + 'custom'">{{ subItem }}</public-label>
            </div>
          </router-link>
        </li>
      </ul>
      <div class="public-pagination container">
        <el-pagination
          background
          layout="prev, pager, next, jumper"
          :total="data.count"
          :page-size="data.limit"
          :pager-count="5"
          @current-change="handlePage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import publicBanner from '@/components/public/PublicBanner'
import LabelCheck from '@/components/myPage/LabelCheck'
import PublicLabel from '@/components/public/PublicLabel'
import { solutionLabelApi, solutionListApi } from '@/api/solutions'
import { mapState } from 'vuex'
export default {
  components: {
    publicBanner,
    PublicLabel,
    LabelCheck
  },
  data() {
    return {
      keywords: '', // 搜索关键字
      isDefault: true, // 默认展开
      defaultList: [], // label选中数据
      labelList: [], // label完整数据
      data: {}, // 数据列表
      labelId: [], // label完整数据/选中的集合
      labelIdSelected: [], // label选中数据/选中的集合
      screenWidth: document.documentElement.clientWidth, // 屏幕宽度
      isMobileFilter: false, // 当移动端时且filter置顶filter设置不同样式
      isMobile: false // 判断是否是移动端
    }
  },
  computed: {
    ...mapState({
      bannerInfo: (state) => state.common.bannerInfo
    })
  },
  watch: {
    // 屏幕尺寸判断
    screenWidth: {
      handler() {
        if (this.screenWidth <= 768) {
          this.isDefault = false
          this.isMobile = true
        } else {
          this.isMobile = false
        }
      }
    }
  },
  created() {
    this.getBanner()
    this.getLabel()
    this.handleList()
  },
  mounted() {
    var windowWidth = document.documentElement.clientWidth
    if (windowWidth <= 768) {
      this.isDefault = false
      this.isMobile = true
    } else {
      this.isMobile = false
    }
  },
  methods: {
    // 获取 banner 图片
    getBanner() {
      this.$store.dispatch('common/getBannerImg', { num: 52 })
    },
    // 解决方案列表接口封装
    getList(type, page, solutionName, label) {
      solutionListApi({
        type: type,
        solution_name: solutionName,
        label: label,
        page: page
      }).then(res => {
        this.data = res.data
      })
    },
    // 获取解决方案label标签
    getLabel() {
      solutionLabelApi({ type: 2 }).then(res => {
        this.labelList = res.data
        this.labelList.forEach((item, index) => {
          this.$set(item, 'value', [])
        })
      })
    },
    // 获取解决方案列表
    handleList() {
      this.getList(2, 1)
    },
    // 展开折叠
    handleFold() {
      this.isDefault = !this.isDefault
      this.isMobileFilter = !this.isMobileFilter
      // 判断在移动端且filter-box置顶时 设置样式
      if (document.querySelector('.filter-by-box').getBoundingClientRect().top <= 60 && this.screenWidth <= 768) {
        this.isMobileFilter = true
      } else {
        this.isMobileFilter = false
      }
      if (this.isDefault) {
        // 当前展开
        this.labelList.forEach(item => {
          for (let i = item.value.length - 1; i >= 0; i--) {
            if (this.defaultList.every(s => s.id !== item.value[i].id)) {
              item.value.splice(i, 1)
            }
          }
        })
        if (this.isMobileFilter) {
          document.querySelector('.filter-by-box').style.height = `100%`
          document.querySelector('.filter-by-box').style.position = 'fixed'
        } else {
          document.querySelector('.filter-by-box').style.height = 'auto'
        }
      } else {
        // 当前合拢
        let _arr = []
        this.labelList.forEach(item => {
          _arr = [..._arr, ...item.value]
        })
        this.defaultList = _arr
        // 合拢时filter高
        document.querySelector('.filter-by-box').style.height = 'auto'
        document.querySelector('.filter-by-box').style.position = 'sticky'
        this.isMobileFilter = false
      }
    },
    // 搜索
    handleSearch(keywords) {
      this.keywords = keywords
      this.getList(2, 1, keywords)
    },
    // 筛选搜索
    handleScreen() {
      this.labelId = []
      this.labelIdSelected = []
      this.labelList.forEach(item => {
        item.value.forEach(t => {
          this.labelId.push(t.id)
        })
      })
      this.defaultList.forEach(item => {
        this.labelIdSelected.push(item.id)
      })
      this.getList(2, 1, this.keywords, this.isDefault ? this.labelId : this.labelIdSelected)
    },
    // 清空筛选
    handleReset() {
      this.defaultList = []
      this.labelList.forEach((item, index) => {
        this.$set(item, 'value', [])
      })
      this.getList(2, 1, this.keywords)
    },
    // 分页
    handlePage(page) {
      this.getList(2, page, this.keywords, this.isDefault ? this.labelId : this.labelIdSelected)
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common';
.solutions-wrap {
  .filter-by-box {
		background: #ffffff;
    padding: 20px 0 40px 0;
		.mobile-filter-by-title{
			display: none;
		}
    .filter-by-title {
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      .link {
        cursor: pointer;
        .title {
          font-size: 24px;
          font-weight: bold;
          color: #303133;
          line-height: 32px;
          margin-right: 16px;
          display: inline-block;
        }
        .iconfont {
          display: inline-block;
          font-size: 24px;
          color: #979797;
          transform: rotate(0);
          .transition();
          &.default {
            transform: rotate(180deg);
          }
        }
      }
    }
    .filter-by-list {
      .filter-by-item {
        display: flex;
        & + .filter-by-item {
          margin-top: 8px;
        }
        .list-title {
          font-size: 18px;
          font-weight: bold;
          line-height: 24px;
          flex-shrink: 0;
        }
        .child-list {
          display: flex;
          flex-wrap: wrap;
          margin-left: 24px;
        }
      }
    }
    .default-list {
      /deep/ .options-wrap {
        .option-item {
          border-color: @theme;
          .label-text {
            color: @theme;
          }
        }
      }
    }
    .filter-by-btn {
      text-align: center;
      margin-top: 8px;
    }
  }
  .solutions-list {
    padding: 48px 0;
    background: #F4F4F5;
    .list {
      display: flex;
      flex-wrap: wrap;
      .list-item {
        width: calc(33.333333% - 11px);
        margin: 0 16px 16px 0;
        background: #ffffff;
        border: 1px solid #E4E7ED;
				&:hover{
					box-shadow: 0px 6px 18px 0px rgba(214, 214, 214, 0.5);
					.link{
						.text-box{
							.title{
								color: @theme;
							}
						}
					}
				}
        &:nth-child(3n) {
          margin-right: 0;
        }
        .link {
          display: block;
          .img-box {
            height: 237px;
            .img-bg {
              height: 237px;
            }
          }
          .text-box {
            padding: 20px;
            border-top: 1px solid #E4E7ED;
            .title {
              font-size: 18px;
              font-weight: 600;
              color: #303133;
              line-height: 24px;
              margin-bottom: 20px;
            }
            /deep/ .options-wrap {
              .option-item {
                margin-bottom: 8px;
              }
            }
          }
        }
      }
    }
  }
@media screen and (max-width:768px) {
	.filter-by-box{
		width: 100%;
		padding: 14px 5.5% 13px;
		position: sticky;
		top: 60px;
		z-index: 9;
		.shadow();
		.filter-by-title{
			display: none;
		}
    .filter-by-list {
      .filter-by-item {
        display: block;
         .child-list {
           margin-left: 0;
           margin-top: 8px;
         }
      }
    }
		.mobile-filter-by-title{
			display: block;
			.link{
				cursor: pointer;
				display: flex;
				justify-content: space-between;
				.title{
					font-size: 16px;
          font-weight: bold;
          color: #303133;
          line-height: 21px;
				}
				.iconfont {
          display: inline-block;
          font-size: 24px;
          color: #979797;
          transform: rotate(0);
          .transition();
          &.default {
            transform: rotate(180deg);
          }
        }
			}
		}
	}
	.mobileFilter {
		overflow-y: scroll;
		.scroll-bar();
		.shadow();
		.filter-by-btn{
			position: fixed;
			left: 0;
			bottom: 0;
			height: 59px;
			width: 100%;
			padding-top: 9px;
			border-top: 1px solid #E4E7ED;

		}
	}
	.solutions-list{
		padding-top:20px;
		.list{
			flex-direction: column;
			.list-item{
				width: 100%;
				margin: 0 0 20px 0;
				.link{
					.img-box{
						height: 190px;
						.img-bg{
							height: 190px;
						}
					}
					.text-box{
						padding: 14px 16px 20px;
						.title{
							margin-bottom: 13px;
						}
					}
				}
			}
		}
	}
}
}
</style>

<style lang="less" scoped>
.solutions-wrap {
  /deep/ .public-banner {
    .banner-bg {
      .banner-info {
        .banner-search {
          .el-input__inner {
            border-radius: 36px;
          }
        }
      }
    }
  }
  /deep/ .public-label {
    margin: 0 8px 8px 0;
    .label-text {
      font-size: 16px;
      font-size: 16px;
      line-height: 21px;
    }
  }
@media screen and (max-width:768px){
	/deep/ .public-banner{
		.banner-bg{
			height: 240px !important ;
			.banner-info{
				.banner-search{
					.el-input__inner{
						height: 48px;
						border-radius: 24px;
					}
					.el-input__suffix{
						padding-top: 8px;
						right: 8px;
						.el-input__suffix-inner{
							.iconfont{
								font-size: 32px;
							}
						}
					}
				}
			}
		}
	}
	/deep/ .public-label{
		margin: 0 6px 6px 0;
		.label-text{
			font-size: 12px;
			line-height: 16px;
		}
	}
	/deep/.public-pagination{
		padding: 2px 0 0 0;
		.el-pagination__editor{
			.el-input{
				.el-input__inner{
					min-width: 36px;
					height: 28px;
				}
			}
		}
		.el-pagination__jump{
			display: block;
			margin: 16px 0 0 0;
		}
	}
}
}
</style>
