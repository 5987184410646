<template>
  <public-dialog
    :visible="contactDialog"
    :element-config="elementUsed"
    @submit="handleSubmit"
    @cancel="handleClose"
    :displayTitle="displayTitle"
  >
    <div class="contact-dialog-content">
      <div class="contact-text">
        <h4 class="contact-title">{{LP.lang_contact_form}}</h4>
        <p class="contact-subtitle">{{LP.lang_contact_information}}</p>
        <el-form ref="form" :model="form" :rules="rules" :label-position="labelPosition" class="contact-form">
          <el-form-item prop="company_email">
            <el-radio-group v-model="form.company_email">
              <el-radio label="technical_email">
                <p class="email-title">{{LP.lang_dipp_for_technical_assistance}}:<span class="email">{{ companyEmail.technical_email }}</span></p>
              </el-radio>
              <el-radio label="sales_email">
                <p class="email-title">{{LP.lang_dipp_for_sales_assistance}}:<span class="email">{{ companyEmail.sales_email }}</span></p>
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="LP.lang_your_name" prop="name">
            <el-input v-model="form.name" />
          </el-form-item>
          <el-form-item :label="LP.lang_your_email" prop="email">
            <el-input v-model="form.email" />
          </el-form-item>
          <el-form-item :label="LP.lang_phone" prop="phone">
            <el-input v-model="form.phone" />
          </el-form-item>
          <el-form-item :label="LP.lang_dipp_company_name" prop="company_name">
            <el-input v-model="form.company_name" />
          </el-form-item>
          <el-form-item :label="LP.lang_your_company_location" prop="company_location">
            <el-select v-model="form.company_location" placeholder="Select">
              <el-option
                v-for="(item, index) in locationList"
                :key="index"
                :label="item.region_name"
                :value="item.region_id"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </public-dialog>
</template>

<script>
import { solutionContactApi, contactSubmitApi, countrySelectApi } from '@/api/solutions'
import PublicDialog from '@/components/public/PublicDialog'
export default {
  components: {
    PublicDialog
  },
  props: {
    // 详情数据
    data:{},
    // 是否展示弹窗
    contactDialog: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      displayTitle: false, // 关闭solution详情页contact弹窗自带的elementUI-title
      labelPosition: 'top',
      elementUsed: {
        title: 'Notice'
      },
      form: {
        company_email: '',
        name: '',
        email: '',
        phone: '',
        company_name: '',
        company_location: ''
      },
      rules: {
        company_email: [
          { required: true, message: 'Please select at least one mailbox', trigger: 'change' }
        ],
        name: [
          { required: true, message: 'name is required', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'email is required', trigger: 'blur' },
          { type: 'email', message: 'email is not a valid email', trigger: ['blur', 'change'] }
        ],
        company_name: [
          { required: true, message: 'company is required', trigger: 'blur' }
        ],
        company_location: [
          { required: true, message: 'Location is required', trigger: 'blur' }
        ]
      },
      companyEmail: {}, // 邮箱
      locationList: [] // 选择国家
    }
  },
  async mounted() {
    // await this.getContactApi()
    await this.getCountry()
    this.companyEmail = this.data
  },
  methods: {
    // 获取邮箱接口
    // async getContactApi() {
    //   await solutionContactApi({
    //     id: 6422
    //   }).then(res => {
    //     this.companyEmail = res.data
    //   })
    // },
    // 获取国家下拉接口
    async getCountry() {
      await countrySelectApi().then(res => {
        this.locationList = res.data
      })
    },
    // 提交表单
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          contactSubmitApi({
            id: this.companyEmail.solution_id,
            name: this.form.name,
            email: this.form.email,
            phone: this.form.phone,
            company_name: this.form.company_name,
            company_email: this.form.company_email == 'technical_email' ? this.companyEmail.technical_email : this.companyEmail.sales_email,
            company_location: this.form.company_location
          }).then(res => {
            if (res.status === '00') {
              this.$message.success('submit successfully')
            }
          })
            .catch(err => {
              this.$message.error(err.message)
            })
        } else {
          return false
        }
      })
    },
    // 清空表单
    handleCancel(formName) {
      this.$refs[formName].resetFields()
    },
    // 关闭弹窗
    handleClose() {
      this.$emit('close')
      this.$refs.form.resetFields()
    }
  }
}
</script>

<style lang="less" scoped>
.public-dialog-container {
  min-width: 920px;
  padding: 30px 40px;
  .contact-dialog-content {
    display: flex;
    .contact-text {
      width: 100%;
      .contact-title {
        font-size: 22px;
        padding-bottom: 20px;
        border-bottom: 1px solid #ccc;
      }
      .contact-subtitle {
        font-size: 15px;
        padding-bottom: 30px;
        padding-top: 20px;
      }
    }
  }
  .el-select,
  .el-radio {
    width: 100%;
  }
  .el-radio {
    color: #999;
    font-size: 16px;
    line-height: 30px;
    display: flex;
    align-items: center;
    .email-title {
      color: #999;
      font-size: 16px;
      line-height: 30px;
      .email {
        color: #018fd1;
        padding-left: 16px;
      }
    }
  }
  .el-form-item__label {
    line-height: 28px;
  }
  @media screen and (max-width: 1400px) {
    min-width: 620px;
  }
  @media screen and (max-width: 768px) {
    min-width: 92%;
  }
}
</style>
<style lang="less">
@media screen and (max-width: 768px) {
	.public-dialog-container .el-radio .email-title .email{
		display: block ;
		padding-left: 0 !important;
	}
	.public-dialog-container .el-radio, .el-radio__inner, .el-radio__input{
		top: -6px;
	}
}
</style>
